import React from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import MainPage from './components/MainPage';
import StatsPage from './components/StatsPage';
import SubmitAttendanceForm from './components/SubmitAttendanceForm';
import RewardsPage from './components/RewardsPage';
import { Search } from 'lucide-react';
import './App.css';

function Header() {
  return (
    <header className="py-2">
      <div className="container mx-auto">
        <nav className="flex justify-center items-center space-x-6">
          <NavLink to="/search" className="nav-link">
            <Search size={16} />
          </NavLink>
          <NavLink to="/" className="nav-link">
            Home
          </NavLink>
          <NavLink to="/stats" className="nav-link">
            Stats
          </NavLink>
          <NavLink to="/submit" className="nav-link">
            Submit Attendance
          </NavLink>
          <NavLink to="/rewards" className="nav-link">
            Rewards
          </NavLink>
        </nav>
      </div>
    </header>
  );
}

function Footer() {
  return (
    <>
      <hr className="border-t border-gray-300 my-8" />
      <footer className="bg-[#3C3A47] text-white py-4">
        <div className="container mx-auto text-center">
          <p>Have a question about this website? eli[at]sillystreetcomedy.com</p>
        </div>
      </footer>
    </>
  );
}

function App() {
  return (
    <Router>
      <div className="bg-[#F2E8F9] min-h-screen font-sans flex flex-col">
        <Header />
        <main className="container mx-auto mt-8 p-4 flex-grow">
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/stats" element={<StatsPage />} />
            <Route path="/submit" element={<SubmitAttendanceForm />} />
            <Route path="/rewards" element={<RewardsPage />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
