import React from 'react';
import './MainPage.css';

// Info Section Component
const InfoSection = () => (
  <section className="section">
    <h2 className="section-title">Open Mic Information</h2>
    <div className="info-grid">
      <div className="info-box">
        <h3>Venue</h3>
        <p>Gib's</p>
      </div>
      <div className="info-box">
        <h3>Address</h3>
        <p>1380 Williamson St, Madison, WI 53703</p>
      </div>
      <div className="info-box">
        <h3>Date and Time</h3>
        <p>Every Monday at 8 PM<br />List at 7:30 PM</p>
      </div>
    </div>
  </section>
);

// Newsletter Section Component
const NewsletterSection = () => (
  <section className="section">
    <h2 className="section-title">Newsletter Subscription</h2>
    <div className="newsletter-container">
      <iframe
        src="https://sillystreet.beehiiv.com/subscribe"
        frameBorder="0"
        scrolling="no"
        className="newsletter-frame"
        title="Newsletter Subscription"
      />
    </div>
  </section>
);

const HostCard = ({ host, isCurrent }) => {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <div
      className={`host-card ${isCurrent ? 'current' : ''}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="host-card-image-container">
        <img
          src={isHovered ? host.hoverImage : host.image}
          alt={host.name}
          className="host-card-image"
        />
      </div>
      <div className="host-card-content">
        <h3 className="host-card-name">{host.name}</h3>
        <p className="host-card-month">{host.month}</p>
        <p className="host-card-bio">{host.bio}</p>
      </div>
    </div>
  );
};

// Hosts Section Component
const HostsSection = ({ hosts }) => (
  <section className="section">
    <h2 className="section-title">Upcoming Hosts</h2>
    <div className="host-cards-grid">
      {hosts.map((host, index) => (
        <HostCard key={host.name} host={host} isCurrent={index === 0} />
      ))}
    </div>
  </section>
);

// Footer Component
const Footer = () => (
  <footer className="footer">
    <p>
      The Silly Street Open Mic is a cooperative effort of the local comedians of Madison.
      Each month, a new comedian takes the reins, hosting the mic and adding their own unique
      flair and intrigue to the show. Stop by your first open mic to join the fun with your own
      co-op card, that you can redeem for free tickets, drinks, and pizza.
    </p>
  </footer>
);

// Main Page Component
const MainPage = () => {
  // Hosts data
  const hosts = [
    {
      name: "Will Byrd",
      month: "October",
      bio: "Hosting in October",
      image: "/October.png",
      hoverImage: "/October2.png"
    },
    {
      name: "Andrew Rynning",
      month: "November",
      bio: "Hosting in November",
      image: "/November.png",
      hoverImage: "/November2.png"
    },
    {
      name: "Dan Gantman",
      month: "December",
      bio: "Hosting in December",
      image: "/December.png",
      hoverImage: "/December2.png"
    },
  ];

  return (
    <div className="container">
      <header className="header">
        <h1 className="main-title">Silly Street Open Mic</h1>
      </header>

      <InfoSection />
      <NewsletterSection />
      <HostsSection hosts={hosts} />
      <Footer />
    </div>
  );
};

export default MainPage;
