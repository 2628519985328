import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, Cell, ResponsiveContainer } from 'recharts';
import axios from 'axios';
import './StatsPage.css';

function PaginatedAttendeeList({ attendees }) {
  const [currentPage, setCurrentPage] = useState(1);
  const attendeesPerPage = 10;

  const indexOfLastAttendee = currentPage * attendeesPerPage;
  const indexOfFirstAttendee = indexOfLastAttendee - attendeesPerPage;
  const currentAttendees = attendees.slice(indexOfFirstAttendee, indexOfLastAttendee);

  const totalPages = Math.ceil(attendees.length / attendeesPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <ol start={indexOfFirstAttendee + 1} className="attendee-list">
        {currentAttendees.map((attendee, index) => (
          <li key={index} className="attendee-name">{attendee}</li>
        ))}
      </ol>
      {totalPages > 1 && (
        <div className="pagination">
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNumber) => (
            <button
              key={pageNumber}
              onClick={() => handlePageClick(pageNumber)}
              className={`page-button ${currentPage === pageNumber ? 'active' : ''}`}
            >
              {pageNumber}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

function LineupsSection() {
  const [lineups, setLineups] = useState([]);

  useEffect(() => {
    const fetchLineups = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/lineups`);
        setLineups(response.data);
      } catch (error) {
        console.error('Error fetching lineups:', error);
      }
    };

    fetchLineups();
  }, []);

  // Function to adjust the date
  const adjustDate = (dateString) => {
    const date = new Date(dateString);
    date.setDate(date.getDate());
    return date.toLocaleDateString();
  };

  return (
    <section className="lineups-section">
      <h2 className="lineups-heading">Past Lineups</h2>
      {lineups.length === 0 ? (
        <p>No lineup data available yet.</p>
      ) : (
        <div className="lineups-grid">
          {lineups.map((lineup) => (
            <div key={lineup.date} className="lineup-card">
              <h3 className="lineup-date">{adjustDate(lineup.date)}</h3>
              <PaginatedAttendeeList attendees={lineup.attendees.map(a => a.name)} />
            </div>
          ))}
        </div>
      )}
    </section>
  );
}

function StatsPage() {
  const [stats, setStats] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [labels, setLabels] = useState({ value: 'Attendances' });
  const attendeesPerPage = 10;

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/stats`);
        console.log("Fetched stats:", response.data);
        if (Array.isArray(response.data)) {
          const sortedData = response.data
            .sort((a, b) => b.attendance_count - a.attendance_count)
            .map(item => ({
              name: item.name,
              value: item.attendance_count
            }));
          setStats(sortedData);
        } else {
          console.error('Expected an array, got:', response.data);
        }
      } catch (error) {
        console.error('Error fetching stats:', error);
      }
    };

    fetchStats();
  }, []);

  const indexOfLastAttendee = currentPage * attendeesPerPage;
  const indexOfFirstAttendee = indexOfLastAttendee - attendeesPerPage;
  const currentAttendees = stats.slice(indexOfFirstAttendee, indexOfLastAttendee);

  const totalPages = Math.ceil(stats.length / attendeesPerPage);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const customLegendRenderer = (props) => {
    const { payload } = props;

    return (
      <ul className="custom-legend">
        {payload.map((entry, index) => (
          <li key={`item-${index}`} className="legend-item">
            <span className="legend-color" style={{ backgroundColor: entry.color }}></span>
            <span className="legend-label">{labels[entry.dataKey] || entry.value}</span>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="container">
      <h1 className="heading">Comedian Attendance Stats</h1>
      <div className="chart-container">
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={currentAttendees} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid vertical={false} stroke="#cccccc" strokeWidth={1} />
            <XAxis
              dataKey="name"
              stroke="#3C3A47"
              strokeWidth={3}
              axisLine={{ strokeWidth: 3 }}
              tickLine={false}
            />
            <YAxis
              stroke="#3C3A47"
              strokeWidth={3}
              axisLine={{ strokeWidth: 3 }}
              tickLine={false}
              allowDecimals={false}
            />
            <Legend content={customLegendRenderer} />
            <Bar dataKey="value" name={labels['value']} isAnimationActive={false}>
              {currentAttendees.map((entry, index) => (
                <Cell key={`cell-${index}`} fill="#7F4FA8" />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        {totalPages > 1 && (
          <div className="pagination">
            {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNumber) => (
              <button
                key={pageNumber}
                onClick={() => handlePageClick(pageNumber)}
                className={`page-button ${currentPage === pageNumber ? 'active' : ''}`}
              >
                {pageNumber}
              </button>
            ))}
          </div>
        )}
      </div>
      <LineupsSection />
    </div>
  );
}

export default StatsPage;
