import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SubmitAttendanceForm.css';

const VALID_STATES = [
  'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
  'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
  'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
  'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
  'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
];

const START_DATE = '2024-11-12';

function SubmitAttendanceForm() {
  const [formData, setFormData] = useState({
    sillyId: '',
    name: '',
    email: '',
    city: '',
    state: '',
    date: ''
  });
  const [error, setError] = useState('');
  const [availableMondays, setAvailableMondays] = useState([]);

  useEffect(() => {
    const mondays = [];
    let startDate = new Date(START_DATE);
    for (let i = 0; i < 4; i++) {
      mondays.push(startDate.toISOString().split('T')[0]);
      startDate.setDate(startDate.getDate() + 7);
    }
    setAvailableMondays(mondays);
    setFormData(prevState => ({ ...prevState, date: mondays[0] }));
  }, []);

  const handleChange = (e) => {
    let { name, value } = e.target;

    if (name === 'sillyId') {
      value = value.replace(/\D/g, '').slice(0, 4);
    } else if (name === 'state') {
      value = value.toUpperCase().slice(0, 2);
    }

    setFormData({ ...formData, [name]: value });
    setError('');
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const sanitizeInput = (input) => {
    return input.replace(/[&<>"']/g, function(match) {
      return {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;'
      }[match];
    });
  };

  const handleGetInfo = async () => {
    if (!/^\d{4}$/.test(formData.sillyId)) {
      setError('ID must be a 4 digit number');
      return;
    }

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/user/${formData.sillyId}`);
      if (response.data) {
        setFormData(prevState => ({
          ...prevState,
          name: sanitizeInput(response.data.name),
          email: sanitizeInput(response.data.email),
          city: sanitizeInput(response.data.city),
          state: response.data.state.toUpperCase().slice(0, 2)
        }));
        setError('');
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setError('No User with that Silly ID');
      } else {
        setError('An error occurred while fetching user information');
      }
      console.error('Error fetching user data:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!/^\d{4}$/.test(formData.sillyId)) {
      setError('ID must be a 4 digit number');
      return;
    }

    if (!validateEmail(formData.email)) {
      setError('Please enter a valid email address');
      return;
    }

    if (!VALID_STATES.includes(formData.state)) {
      setError('Please enter a valid US state abbreviation.');
      return;
    }

    const sanitizedFormData = {
      ...formData,
      name: sanitizeInput(formData.name),
      email: sanitizeInput(formData.email),
      city: sanitizeInput(formData.city),
      state: formData.state.toUpperCase().slice(0, 2)
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/submit-attendance`, sanitizedFormData);
      alert(`Welcome! ${sanitizedFormData.name}, your SillyID is ${response.data.sillyId}`);
      setFormData({
        sillyId: response.data.sillyId,
        name: '',
        email: '',
        city: '',
        state: '',
        date: availableMondays[0]
      });
    } catch (error) {
      console.error('Error submitting attendance:', error);
      setError(error.response?.data?.error || 'Failed to submit attendance');
    }
  };

  return (
    <div className="form-container">
      <div className="max-w-md">
        <h2 className="heading">Submit Attendance</h2>
        <p className="description-text">
          First Timers: fill out your information and select an ID that you will remember! It will let you know if it's already been taken. You can use it to auto-fill the rest of your information each time.
        </p>
        <p className="description-text">
          Submit your attendance from the open mic! This attendance will be used to track eligibility for rewards.
        </p>
        {error && <p className="error-text">{error}</p>}
        <form onSubmit={handleSubmit} className="attendance-form">
          <div className="form-group">
            <div className="controls">
              <input
                id="sillyId"
                type="text"
                name="sillyId"
                value={formData.sillyId}
                onChange={handleChange}
                maxLength="4"
                pattern="\d{4}"
                required
              />
              <label htmlFor="sillyId" className={formData.sillyId ? 'active' : ''}>Silly ID (4 digits)</label>
            </div>
            <button
              type="button"
              onClick={handleGetInfo}
              className="get-info-btn"
            >
              Autofill
            </button>
          </div>
          <div className="form-group">
            <div className="controls">
              <input
                id="name"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <label htmlFor="name" className={formData.name ? 'active' : ''}>Full Name</label>
            </div>
          </div>
          <div className="form-group">
            <div className="controls">
              <input
                id="email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <label htmlFor="email" className={formData.email ? 'active' : ''}>Email</label>
            </div>
          </div>
          <div className="form-group">
            <div className="controls">
              <input
                id="city"
                type="text"
                name="city"
                value={formData.city}
                onChange={handleChange}
              />
              <label htmlFor="city" className={formData.city ? 'active' : ''}>City</label>
            </div>
          </div>
          <div className="form-group">
            <div className="controls">
              <input
                id="state"
                type="text"
                name="state"
                value={formData.state}
                onChange={handleChange}
                maxLength="2"
              />
              <label htmlFor="state" className={formData.state ? 'active' : ''}>State</label>
            </div>
          </div>
          <div className="form-group">
            <div className="controls">
              <select
                id="date"
                name="date"
                value={formData.date}
                onChange={handleChange}
                required
              >
                {availableMondays.map(date => (
                  <option key={date} value={date}>{new Date(date).toLocaleDateString()}</option>
                ))}
              </select>
              <label htmlFor="date" className="active">Date</label>
            </div>
          </div>
          <div className="form-group">
            <button type="submit" className="submit-btn">
              Submit Attendance
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default SubmitAttendanceForm;
