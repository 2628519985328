import React from 'react';
import './RewardsPage.css';

// Audience rewards data
const audienceRewards = [
  {
    name: "Free Ticket to a Local Show",
    description: "Submit your email to recieve a FREE ticket to an upcoming local show in the Willy Street Neighborhood",
    requirement: "Attend 1 open mics",
    image: "/1.png"
  },
  {
    name: "1 Free Drink",
    description: "Redeem your punchcard with 5 punches for a free drink with Eli Wilz.",
    requirement: "Attend 5 open mics",
    image: "/5.png"
  },
  {
    name: "1 Free Pizza",
    description: "Redeem your punchcard with 10 punches for a Grampa's Pizza Giftcard",
    requirement: "Attend 10 open mics",
    image: "/10.png"
  }
];

// Comic rewards data
const comicRewards = [
  {
    name: "Discord Access",
    description: "Get access to the Silly Street Discord.",
    requirement: "Perform your first Silly Street Open Mic",
    image: "/DISCORD.png"
  },
  {
    name: "Jump the List",
    description: "Pick your spot in the lineup. No matter when you show up, you get to select your spot",
    requirement: "Perform on 5 Silly Street Open Mics",
    image: "/Jump-the-List.png"
  },
  {
    name: "10 Minute Set",
    description: "Get a 10-minute feature spot during the Silly Street Open Mic. Max 1 redemption per open mic.",
    requirement: "Perform on 10 Silly Street Open Mics",
    image: "/10.png"
  }
];

function RewardCard({ reward }) {
  return (
    <div className="reward-card">
      <img
        src={reward.image}
        alt={reward.name}
      />
      <div className="reward-card-content">
        <h3 className="reward-card-name">{reward.name}</h3>
        <p className="reward-card-description">{reward.description}</p>
        <p className="reward-card-requirement">{reward.requirement}</p>
      </div>
    </div>
  );
}

function RewardsPage() {
  return (
    <div className="container">
      <h1 className="heading">Rewards Program</h1>


      <section className="mb-8">
        <h2 className="subheading">Audience Rewards</h2>
        <p className="description-text">
          To show our appreciation for joining us at the Silly Street Open Mic, we're introducing an audience rewards punch card!
          Every time you buy a drink or food at the show, you'll get a punch—no minimum purchase required, just grab
          something and you're in. Comedians, are not eligible for audience rewards (but we still love you!).
        </p>
        <div className="reward-cards">
          {audienceRewards.map((reward) => (
            <RewardCard key={reward.name} reward={reward} />
          ))}
        </div>
      </section>

      <section className="mb-8">
        <h2 className="subheading">Comic Rewards</h2>
        <p className="description-text">
        Rewards are intended to be a fun way to help comics motivate themselves to regularly show up and work on their jokes. Implementation and
        elligibility for rewards and their redemption are up to the discretion of the hosts of the Silly Street Open Mic.
        </p>
        <div className="reward-cards">
          {comicRewards.map((reward) => (
            <RewardCard key={reward.name} reward={reward} />
          ))}
        </div>
      </section>
    </div>
  );
}

export default RewardsPage;
